import React, { useContext } from "react";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import InlineLink from "plaid-threads/InlineLink";

import Link from "../Link";
import Context from "../../Context";

import styles from "./index.module.scss";

const Header = () => {
  const {
    itemId,
    accessToken,
    linkToken,
    linkSuccess,
    isItemAccess,
    backend,
  } = useContext(Context);

  return (
    <div className={styles.grid}>
      <h3 className={styles.title}>Connect Your Bank Account</h3>

      {!linkSuccess ? (
        <>
          <h4 className={styles.subtitle}>
          
          </h4>
          <p className={styles.introPar}>
            By clicking the button below to launch Link - this will link your bank accounts and allow Benedor
            to access your bank accounts via the Plaid Platform.
          </p>
          {/* message if backend is not running and there is no link token */}
          {linkToken == null || backend === false ? (
            <Callout warning>
              Unable to fetch link_token: please make sure your backend server
              is running and that your .env file has been configured with your
              <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
            </Callout>
          ) : linkToken === "" ? (
            <div className={styles.linkButton}>
              <Button large disabled>
                Loading...
              </Button>
            </div>
          ) : (
            <div className={styles.linkButton}>
              <Link />
            </div>
          )}
        </>
      ) : (
        <>
          {isItemAccess ? (
            <h4 className={styles.subtitle}>
              Congratulations! Thank you for linking your bank account.
            </h4>
          ) : (
            <h4 className={styles.subtitle}>
              <Callout warning>
                Unable to link your bank account.
              </Callout>
            </h4>
          )}
        </>
      )}
    </div>
  );
};

Header.displayName = "Header";

export default Header;
