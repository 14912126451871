import React, { useEffect, useContext } from "react";
import { usePlaidLink } from "react-plaid-link";
import Button from "plaid-threads/Button";

import Context from "../../Context";
import axios from 'axios';
import qs from 'querystring';

const BACKEND_URL = 'https://cloud2.benedor.com/';
const APPLICATION_URL = 'https://login.benedor.com/';

const Link = () => {
  const { linkToken, dispatch } = useContext(Context);

  const onSuccess = React.useCallback(
    (public_token: string) => {

      // send public_token to server
      const setToken = async () => {
        const response = await fetch(BACKEND_URL + "/api/set_access_token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `public_token=${public_token}`,
        });
        if (!response.ok) {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
          return;
        }

        const data = await response.json();
        localStorage.setItem("b_id", data.benedor_id);

        dispatch({
          type: "SET_STATE",
          state: {
            itemId: data.item_id,
            accessToken: data.access_token,
            isItemAccess: true,
          },
        });
        
        //POST to Login.Benedor.com
        var postdata = qs.stringify({
          accsstkn: data.access_token,
          itmid: data.item_id,
          uid: data.benedor_id
        });
        axios({
          method: 'POST',
          url: APPLICATION_URL + 'apps/public/save_plaid_access.php',
          data: postdata,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then( function (response) {

        });

      };
      setToken();
      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });

      setTimeout(() => {
        window.history.pushState("", "", "/" + "?benedor_id=" + localStorage.getItem("b_id") );
      }, 1000);
    },
    [dispatch]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return (
    <Button type="button" large onClick={() => open()} disabled={!ready}>
      Connect Using Plaid
    </Button>
  );
};

Link.displayName = "Link";

export default Link;
